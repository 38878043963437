import { graphql } from "gatsby"
import TagComponent from "../components/tag"

export default TagComponent

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    allPost(sort: { fields: date, order: DESC }, filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        timeToRead
        description
        tags {
          name
          slug
        }
        banner {
          childImageSharp {
            fluid(
              maxWidth: 500,
              maxHeight: 200,
              quality: 90
            ) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
        }
      }
    }
  }
`
